import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { collapseAnimation } from 'angular-animations';
import { firstValueFrom } from 'rxjs';
import { first } from 'rxjs/operators';
import { OrderCollectStatus, OrderErpExportStatus, OrderState } from 'src/app/models/orders.models';
import { ShipmentState, ShipmentStatus } from 'src/app/models/shipments.models';
import { ISupplierConfiguration } from 'src/app/models/suppliers';
import { AdminService } from 'src/app/services/admin.service';
import { AuthorizationService } from 'src/app/services/authurization.service';
import { IOrderSearchRequest, OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { ConfigStateFeature } from 'src/app/state/config.reducer';
declare var $: any;

@Component({
  selector: 'home',
  templateUrl: './home.page.component.html',
  styleUrls: ['./home.page.component.css'],
  animations: [
    collapseAnimation({ duration: 300 })
  ]
})
export class HomePageComponent implements OnInit {

  public orderStatistics: { [key: string]: StatisticRecord } = {};
  public DateTypes = StatisticRecord.DateTypes;
  private _configuration: ISupplierConfiguration;

  constructor(private _orderService: OrderService, private store: Store, private _adminService: AdminService, private _authorizationService: AuthorizationService, private _userService: UserService, private _router: Router) {

  }

  public get HasStatsHidden(): boolean {
    return (
      (this.orderStatistics?.readyForPrint && !this.orderStatistics.readyForPrint.Enabled) ||
      (this.orderStatistics?.readyForERPExport && !this.orderStatistics.readyForERPExport.Enabled) ||
      (this.orderStatistics?.readyToCollect && !this.orderStatistics.readyToCollect.Enabled) ||
      (this.orderStatistics?.exportOrderMissingRequirements && !this.orderStatistics.exportOrderMissingRequirements.Enabled) ||
      (this.IsStockEnabled && this.orderStatistics?.outOfStock && !this.orderStatistics.outOfStock.Enabled)
    );
  };

  public get IsStockEnabled(): boolean {
    return this._configuration?.StockSetting?.Enable;
  }

  async ngOnInit() {
    this._configuration = await firstValueFrom(this.store.select(ConfigStateFeature.selectConfiguration));
    this.loadOrderStatistics();
  }
  private async loadOrderStatistics() {

    this.orderStatistics.inDraft = new StatisticRecord(this._orderService, {
      PageSize: 0,
      OrderState: [OrderState.Draft]
    }, true);

    this.orderStatistics.shipmentError = new StatisticRecord(this._orderService, {
      PageSize: 0,
      ShipmentState: [ShipmentState.IgnoreValidation]
    }, true);

    this.orderStatistics.exportOrderMissingRequirements = new StatisticRecord(this._orderService, {
      PageSize: 0,
      ERPExportStatus: [OrderErpExportStatus.ExportOrderMissingRequirements]
    }, false);


    this.orderStatistics.readyForPrint = new StatisticRecord(this._orderService, {
      PageSize: 0,
      NotShipmentState: [ShipmentState.IgnoreValidation],
      OrderState: [OrderState.Active],
      ShipmentStatus: [ShipmentStatus.New]
    }, false);

    this.orderStatistics.reportedToSeller = new StatisticRecord(this._orderService, {
      PageSize: 0,
      NotShipmentState: [ShipmentState.IgnoreValidation],
      OrderState: [OrderState.Active],
      ShipmentStatus: [ShipmentStatus.Printed]
    }, false);


    this.orderStatistics.readyForERPExport = new StatisticRecord(this._orderService, {
      PageSize: 0,
      NotShipmentState: [ShipmentState.IgnoreValidation],
      OrderState: [OrderState.Active],
      ERPExportStatus: [OrderErpExportStatus.Ready]
    }, false);

    this.orderStatistics.readyToCollect = new StatisticRecord(this._orderService, {
      PageSize: 0,
      OrderState: [OrderState.Active],
      CollectStatus: [OrderCollectStatus.Ready]
    }, false);

    if (this.IsStockEnabled) {
      this.orderStatistics.outOfStock = new StatisticRecord(this._orderService, {
        PageSize: 0,
        OrderState: [OrderState.OutOfStock]
      }, false);
    }


    var tasks = [];

    for (const key in this.orderStatistics) {
      if (Object.prototype.hasOwnProperty.call(this.orderStatistics, key)) {
        if (this.orderStatistics[key].Enabled) tasks.push(this.orderStatistics[key].LoadOrderStatisticsData());
        else this.loadByUserPreference(key);
      }
    }

    await Promise.all(tasks);
  }

  private loadByUserPreference(key: string) {
    this._userService.GetUserPreference(this.GetUserPreferenceKey(key)).subscribe(value => {
      this.orderStatistics[key].SetEnable(!value);
    });
  }

  private GetUserPreferenceKey(uiName: string): string | null {
    switch (uiName) {
      case "readyForERPExport":
        return "HomePage_HideReadyForERPExport"
      case "readyForPrint":
        return "HomePage_HideReadyForPrint"
      case "readyToCollect":
        return "HomePage_ReadyToCollect"
      case "outOfStock":
        return "HomePage_OutOfStock"
      case "exportOrderMissingRequirements":
        return "HomePage_ExportOrderMissingRequirements"
      default:
        return null;
    }
  }

  public GoToWizard(record: StatisticRecord, dateKey: string) {

    var query = JSON.parse(JSON.stringify(record.GetQuery(dateKey)));
    delete query.PageSize;
    this._router.navigate(['/orders-wizard'], { queryParams: query });
  }

  public GoToOrders(record: StatisticRecord, dateKey: string) {
    var query = JSON.parse(JSON.stringify(record.GetQuery(dateKey)));
    delete query.PageSize;

    this._router.navigate(['/orders'], { queryParams: query });
  }

  public HasRole(roles: string[]): boolean {
    return this._authorizationService.DoseUserHasRole(roles);
  }
  public async ToggleViewStats(key: string) {

    var hide = this.orderStatistics[key].Enabled;
    await this._userService.SetUserPreference(this.GetUserPreferenceKey(key), hide).toPromise();
  }

  public async ResetViewStats() {
    await this._userService.SetUserPreference(this.GetUserPreferenceKey("readyForPrint"), null).toPromise();
    await this._userService.SetUserPreference(this.GetUserPreferenceKey("readyForERPExport"), null).toPromise();
    await this._userService.SetUserPreference(this.GetUserPreferenceKey("readyToCollect"), null).toPromise();
    await this._userService.SetUserPreference(this.GetUserPreferenceKey("exportOrderMissingRequirements"), null).toPromise();
    if (this.IsStockEnabled) await this._userService.SetUserPreference(this.GetUserPreferenceKey("outOfStock"), null).toPromise();
  }

}



class StatisticRecord {

  static DateTypes = ['today', 'yesterday', 'weekAGo', 'total'];
  public today?: number;
  public yesterday?: number;
  public weekAGo?: number;
  public total?: number;

  private loaded: boolean = false;
  constructor(private _orderService: OrderService, private baseQuery: IOrderSearchRequest, private enable: boolean) { }

  public get Enabled() { return this.enable };

  public async SetEnable(value: boolean) {
    this.enable = value;
    if (this.enable) {
      await this.LoadOrderStatisticsData();
    }

  }
  public async LoadOrderStatisticsData() {

    if (!this.enable || this.loaded) return;
    var tasks = [];

    StatisticRecord.DateTypes.forEach(dateType => {
      var query = this.GetQuery(dateType);
      if (query) {
        tasks.push(this._orderService.SearchOrders(query).toPromise().then(result => {
          this[dateType] = result.Result.TotalItemsCount as any;
        }));
      }
    });


    await Promise.all(tasks);
    this.loaded = true;
  }

  public GetQuery(dateType: string): IOrderSearchRequest | null {

    switch (dateType) {
      case 'today':
        return $.extend({
          OperationTimeFrom: this.getBeginningOfDay(0)
        }, this.baseQuery);
      case 'yesterday':
        return $.extend({
          OperationTimeFrom: this.getBeginningOfDay(-1),
          OperationTimeTo: this.getBeginningOfDay(0)
        }, this.baseQuery);
      case 'weekAGo':
        return $.extend({
          OperationTimeFrom: this.getBeginningOfDay(-5),
          OperationTimeTo: this.getBeginningOfDay(-1)
        }, this.baseQuery);
      case 'total':
        return $.extend({}, this.baseQuery);
    }
    return null;

  }

  private getBeginningOfDay(addDays: number): Date {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + addDays);
  }
}