import { Component, EventEmitter, Output, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { OrderService } from '../../../services/order.service';
import { IShipment, IBoxItShipment, IChitaShopsShipment, GetDropPointType, IUPSPickUpShipment, IYDMShopsShipment, ISosnaShopsShipment, IEPostShipment, IOrionShopsShipment } from '../../../models/shipments.models';
import { ShipmentService } from '../../../services/shipment.service';
import { CommonService } from '../../../services/common.service';
import { UserControlEditorMode } from '../../../models/user-controls';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormControlStatus, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'droppoint-shipment-editor',
  templateUrl: './droppoint.shipment.editor.usercontrol.component.html',
  styleUrls: ['./droppoint.shipment.editor.usercontrol.component.css']
})
export class DropPointShipmentEditorUserControlComponent implements OnInit, OnChanges {
  closeResult: string;


  constructor(private _orderService: OrderService,
    private _common: CommonService,
    private _shipmentService: ShipmentService,
    private _modalService: NgbModal,
    private cd: ChangeDetectorRef) { }


  @Output() OnValidChange: EventEmitter<FormControlStatus> = new EventEmitter<FormControlStatus>();
  @Output() ShipmentChange: EventEmitter<IShipment> = new EventEmitter<IShipment>();
  @Input() Shipment!: IBoxItShipment | IChitaShopsShipment | IYDMShopsShipment | ISosnaShopsShipment | IUPSPickUpShipment | IEPostShipment | IOrionShopsShipment ;
  @Input() Mode!: UserControlEditorMode;

  public ValidationFrom: UntypedFormGroup;
  @Output() public ValidationFromChange: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  public ShipmentItemType: string = "";
  @Output() ShipmentItemTypeChange: EventEmitter<string> = new EventEmitter<string>();



  ngOnInit(): void {
    if (!this.Mode) this.Mode = UserControlEditorMode.View;
    if (!this.Shipment.Address) this.Shipment.Address = this._common.CreateNewIAddress();
    this.bindControls();

  }

  ngOnChanges(changes: SimpleChanges): void {
   if (changes.Shipment && !changes.Shipment.firstChange)  this.RaiseChange(changes);
  }



  private bindControls() {

    this.ValidationFrom = new UntypedFormGroup({
      RecipientFullName: new UntypedFormControl(this.Shipment.Recipient.FullName, Validators.required),
      RecipientEmail: new UntypedFormControl(this.Shipment.Recipient.Email, Validators.email),
      DropPoint: new UntypedFormControl(this.Shipment.DropPoint, (control: AbstractControl) => {
        if (control.value && control.value.ItemType == GetDropPointType(this.Shipment.ItemType)) return null;
        else return { required: true }
      })

    });

    this.ValidationFromChange.emit(this.ValidationFrom);

    this.ValidationFrom.statusChanges.subscribe(
      r => {
        this.OnValidChange.emit(r as FormControlStatus);
      });


  }

  public DropPointType(): string {
    return GetDropPointType(this.Shipment.ItemType);
  }

  public refreshAddress() {
    this.Shipment.Address = $.extend(true, {}, this.Shipment.Address);
    this.cd.detectChanges();
  }


  RaiseChange(changes: SimpleChanges): void {
    if (this.ValidationFrom?.controls?.DropPoint) this.ValidationFrom.controls.DropPoint.setValue(this.Shipment.DropPoint);

    this.cd.detectChanges();
    this.ShipmentChange.emit(this.Shipment);
  }


  public open(content, options, onInit, onSave) {
    if (onInit) onInit();
    this._modalService.open(content, options).result.then((result) => {
      if ((result === "save") && (onSave)) onSave();
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}

