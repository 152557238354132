<div class="container-fluid mt-2" *ngIf="SupplierStoreConfiguration && StoreDetails">
  <ng-container *ngIf="!this.SaveInprogress">
    <div class="row">
      <div class="col-8">
        <h2>הגדרת ערוץ מכירה</h2>
        <h3>{{ (SupplierStoreConfiguration?.AliasName && SupplierStoreConfiguration.AliasName.trim() != "") ? SupplierStoreConfiguration.AliasName : StoreDetails.Description}}</h3>
      </div>
      <div class="col-4">
        <img class="LogoImage position-absolute" [src]="StoreDetails.LogoURL" [alt]="StoreDetails.Description" />
      </div>
    </div>
    <div class="row my-2">
      <h5 class="col-3">ערוץ מכירה פעיל</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.Enabled" tabindex="10"></checkbox>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">כינוי</h5>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierStoreConfiguration.AliasName" tabindex="15" />
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">שדה מספר הזמנה</h5>
      <div class="col-3">
        <select *ngIf="ImportFileFields" class="form-control" [(ngModel)]="OverrideDefaultOrdernumberField" (ngModelChange)="OnOverrideDefaultOrdernumberFieldChanged($event)" tabindex="20">
          <option *ngFor="let item of ImportFileFields" [value]="item.id">{{item?.names[0]}}</option>
        </select>
      </div>
      <div class="col-6">במידה ולערוץ יש יותר משדה מזהה אחד בהזמנה<br />(מספר הזמנה או מספר שובר - לדוגמא)</div>
    </div>
    <div class="row my-2">
      <h5 class="col-3">שדה מחיר</h5>
      <div class="col-3 d-flex">
        <select *ngIf="ImportFileFields" class="form-control flex-fill mr-1" [(ngModel)]="OverrideDefaultPriceField" (ngModelChange)="OnOverrideDefaultPriceFieldChanged();" tabindex="20">
          <option *ngIf="OverrideDefaultPriceFieldIncludeNothing" [value]="null">ללא</option>
          <option *ngFor="let item of ImportFileFields" [value]="item.id">{{item?.names[0]}}</option>
        </select>
        <select *ngIf="ImportFileFields" class="form-control flex-fill" [(ngModel)]="OverrideDefaultPriceFieldType" (ngModelChange)="OnOverrideDefaultPriceFieldChanged();" tabindex="20">
          <option *ngFor="let item of OverrideDefaultPriceFieldTypes" [value]="item.id">{{item?.name}}</option>
        </select>
      </div>
      <div class="col-6">במידה ולערוץ יש יותר משדה מחיר אחד או שמערכת צריכה לתחשב במע"מ</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">ערוץ מפיץ בעצמו</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IsSalfDistributer" tabindex="30"></checkbox>
      </div>
      <div class="col-6">בהזמנות של ערוץ המפיץ בעצמו תסומן שיטת ההפצה בשם הערוץ ולא תבוצע ולידציה על כתובת המשלוח שבהזמנה. בנוסף, במידה וכתובת המשלוח ריקה, ההזמנה תסומן באיסוף עצמי.</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">שיטת מציאת מוצר</h5>
      <div class="col-3">

        <select class="form-control flex-fill" [(ngModel)]="SupplierStoreConfiguration.ImportConfiguration.AliasProductMatchingStrategy" tabindex="35">
          <option *ngFor="let option of AliasProductMatchingStrategyOptions" [value]="option">{{option | enumDescription : "AliasProductMatchingStrategyType"}}</option>
        </select>

      </div>
      <div class="col-6" [ngSwitch]="SupplierStoreConfiguration.ImportConfiguration.AliasProductMatchingStrategy">
        <div *ngSwitchCase="7">
          <div>המערכת תחפש מפתח לפי סדר המצאות הנתונים במקור.</div>
          <div>
            מק"ט, מספר יצרן ובסוף לפי שם מוצר
          </div>
        </div>
        <div *ngSwitchCase="4"> המערכת תחפש רק לפי שם המוצר בלבד</div>
        <div *ngSwitchCase="1"> המערכת תחפש רק לפי מק"ט</div>
        <div *ngSwitchCase="24"> המערכת תחפש לפי שילוב מק"ט (אם לא קיים אז לפי מספר יצרן) ושם מוצר</div>
      </div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">החלף כתובת ב:</h5>
      <div class="col-3">
        <input type="text" class="form-control" [disabled]="!SupplierStoreConfiguration.ImportConfiguration.IsSalfDistributer" [(ngModel)]="SupplierStoreConfiguration.RenameSalfDistributerDeliveryMethod" tabindex="40" />
      </div>
      <div class="col-6">בהזמנות של ערוץ המפיץ בעצמו ניתן להחליף את הכתובת שבהזמנה בטקסט אחר כגון "איסוף על ידי מפיץ <b>שם הערוץ</b> שיוצע בקובץ ה- ERP</div>
    </div>
    <div class="row my-2">
      <h5 class="col-3">מספר ספק ב-ERP</h5>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierStoreConfiguration.ERPNumber" tabindex="50" />
      </div>
      <div class="col-6">מספר או כל נתון אחר שמעניינים שיוצג בהזמנות של ערוץ ההפצה בקובץ הייצוא ל-ERP (כגון מספר ספר במערכת ה-ERP)</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">הימנע מטעינת הזמנות קיימות</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IgnoreExistingSupplierNumber" tabindex="55"></checkbox>
      </div>
      <div class="col-6">תהליך היבוא לא יכלול הזמנות שמספר קיים כבר במערכת </div>
    </div>


    <div class="row my-2">
      <h5 class="col-3">נסה לזהות מוצר גם על פי דגם</h5>
      <div class="col-3">
        <checkbox [model]="MatchProductByModelConfiguration" (modelChange)="onMatchProductByModelConfigurationChange($event)" tabindex="60"></checkbox>
      </div>
      <div class="col-6">בעת ייבוא הזמנות בנוסף למק"ט המערכת תנסה לזהות את המוצר גם על פי דגם</div>
    </div>


    <div class="row my-2" *ngIf="StoreDetails.Type == 5">
      <h5 class="col-3">בטל איחוד שורות עוקבות זהות</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IgnoreSimilarOrderLineGrouping" tabindex="70"></checkbox>
      </div>
      <div class="col-6"> האם תהליך היבוא לא ינסה לאחד פריטים מהזמנות עוקבות עם לקוח ויעד זההים </div>
    </div>


    <div class="row my-2">
      <h5 class="col-3">התעלם יבוא הערות הזמנה</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IgnoreOrderRemarks" tabindex="80"></checkbox>
      </div>
      <div class="col-6">האם להתעלם מהערות הזמנה בעת תהליך הייבוא</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">התעלם יבוא הערות משלוח</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IgnoreShipmentRemarks" tabindex="80"></checkbox>
      </div>
      <div class="col-6">האם להתעלם מהערות משלוח בעת תהליך הייבוא</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">מיפוי תוספת שדות ידניים להזמנה </h5>
      <div class="col-3">
        <button type="button" tabindex="70" class="btn btn-outline-primary" (click)="open(editOrderCustomField,{ size: 'lg' },CreateOrderCustomFieldsMappingContiner,UpdateOrderCustomFieldsMapping)">לחץ כאן לצפיה/שינוי</button>
      </div>
      <div class="col-6">מיפוי והגדרה של שדות נוספים בהזמנה</div>
    </div>




    <div class="row my-2">
      <div class="col-12 border-top">
        <h3> משלוחים</h3>
      </div>
    </div>
    <div class="row my-2">
      <h5 class="col-3"> אסטרטגיות חישוב שיטת משלוח</h5>
      <div class="col-3">
        <select class="form-control flex-fill" [(ngModel)]="SelectedShipmentStrategiesKey" tabindex="92">
          <option *ngFor="let strategiesOption of ShipmentStrategiesOptions" [value]="strategiesOption.Key">{{strategiesOption.Text}}</option>
        </select>
      </div>
      <div class="col-6">סדר מציאת שיטת המשלוח יהיה :
        <div *ngFor="let Strategy of SelectedShipmentStrategies index as i ">
          <span class="badge badge-pill badge-dark mx-2"> {{i + 1 }}</span>
          <span>לפי {{Strategy | enumDescription : 'ShipmentMethodFindStrategyType'}}</span>
        </div>
      </div>

    </div>

    <div class="row my-2">
      <h5 class="col-3">מפוי שיטות משלוח</h5>
      <div class="col-3">
        <button type="button" tabindex="95" class="btn btn-outline-primary" (click)="open(editDefaultShipmentMethods,{ size: 'lg' },CreateShipmentTextsToMethods,UpdateShippingMethodDefaultsFromShipmentTextsToMethods)">לחץ כאן לצפיה/שינוי</button>
      </div>
      <div class="col-6">בעת ייבוא הזמנות חישוב שיטת המשלוח תהיה על פי הערכים מקבצי היבוא</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">שיטת חישוב מחיר משלוח</h5>
      <div class="col-3">
        <select class="form-control flex-fill" [(ngModel)]="SupplierStoreConfiguration.ImportConfiguration.ShipingPriceStrategy" tabindex="97">
          <option *ngFor="let option of ShippingPriceStrategyOptions" [value]="option">{{option | enumDescription : "ShippingPriceStrategyType"}}</option>
        </select>
      </div>
      <div class="col-6" [ngSwitch]="SupplierStoreConfiguration.ImportConfiguration.ShipingPriceStrategy">
        <div *ngSwitchCase="1">מחיר המשלוח שהתקבל בהזמנה הוא שיקבע. במידה ולא התקבל המחיר ילקח מההגדרה של ערוץ ההפצה.</div>
        <div *ngSwitchCase="2"> אם מחיר המשלוח בערוץ ההפצה גדול מאפס הוא יקבע כמחיר המשלוח, אחרת לפי המחיר שהתקבל בקובץ.</div>
        <div *ngSwitchCase="4">מחיר המשלוח שהתקבל בהזמנה הוא שיקבע. במידה ולא התקבל מחיר אפס.</div>
      </div>
    </div>

    <div class="row my-2">
      <h5 class="col-3"> טיפול בריבוי משלוחים בהזמנה</h5>
      <div class="col-3">
        <select class="form-control flex-fill" [(ngModel)]="SupplierStoreConfiguration.ImportConfiguration.MultiplyShipmentItemsStrategy" tabindex="99">
          <option *ngFor="let option of MultiplyShipmentItemsStrategyOptions" [value]="option">{{option | enumDescription : "MultiplyShipmentItemsStrategyType"}}</option>
        </select>
      </div>

      <div class="col-6" [ngSwitch]="SupplierStoreConfiguration.ImportConfiguration.MultiplyShipmentItemsStrategy">
        <div *ngSwitchCase="0">כול רשומת משלוח תכלל בהזמנה מה שיגרום למחיר משלוח בהזנה שהוא סיכום של כול הרשומות</div>
        <div *ngSwitchCase="1">רק הרשומה בעלת המחיר הגבוה ביותר תיובא לתוך ההזמנה</div>
        <div *ngSwitchCase="2">מחיר ממוצע יחושב ושיטת המשלוח בעלת המחיר הגבוה ייובא לתוך ההזמנה</div>
        <div *ngSwitchCase="4">רק רשומת המשלוח הראשונה תיובא לתוך ההזמנה</div>
        <div *ngSwitchCase="8">רק רשומת המשלוח האחרונה תיובא לתוך ההזמנה</div>

      </div>
    </div>

    <!--
    <div class="row my-2">
      <div class="col-12 border-top">
        <h3> קטלוג מוצרים</h3>
      </div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">טען קובץ קטלוג </h5>
      <div class="col-3">
        <uploadfile [mode]="1" [(model)]="CatalogFiles"> </uploadfile>
      </div>
      <div class="col-6">
        מגדיר את קטלוג המוצרים עבור {{StoreDetails.Description}} בלבד<br />
      </div>
    </div>
-->

    <div class="row my-2 mt-2">
      <div class="col-12  border-top">
        <h4><u> מחירון</u></h4>
      </div>
    </div>
    <div class="row my-2">
      <h5 class="col-3">טען קובץ מחירים </h5>
      <div class="col-3">
        <uploadfile [mode]="1" [(model)]="PriceListFiles"> </uploadfile>
      </div>
      <div class="col-6">
        מגדיר את מחיר המחירון לכול מוצר עבור {{StoreDetails.Description}} בלבד<br />
        להורדת המחירון השמור במערכת <a routerLink="/print-products" [queryParams]="PrintPriceListURLQueryParams">לחץ כאן</a>
      </div>

    </div>

    <div class="row my-2">
      <h5 class="col-3">השלם \ דרוס מחירים</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.OverridePriceList" tabindex="100"></checkbox>
      </div>
      <div class="col-6">מחיר המוצר יהיה מחיר המחירון ולא מחיר המגיע מ{{StoreDetails.Description}}</div>
    </div>

    <div class="row my-2">
      <h5 class="col-3">התראת מחיר אפס</h5>
      <div class="col-3">
        <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.WarnOnZeroPriceOrderItem" tabindex="100"></checkbox>
      </div>
      <div class="col-6">המערכת תתריע כאשר אחד מהמוצרים בהזמנה יהיה במחיר אפס (חינם)</div>
    </div>

    <ng-container *ngIf="!SupplierStoreConfiguration.ImportConfiguration.OverridePriceList">


      <div class="row my-2">
        <h5 class="col-3"> התראת חריגה באחוז ממחיר מחירון</h5>
        <div class="col-3">
          <div class="d-flex flex-row">
            <div class="text-nowrap  align-self-center"> גדול ב</div>
            <div class="input-group ml-1">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>
              <input type="number" class="form-control" [(ngModel)]="SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent" min="0" tabindex="80">

            </div>


            <div class="text-nowrap pl-3  align-self-center">
              קטן ב
            </div>
            <div class="input-group ml-1 ">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">%</span>
              </div>

              <input type="number" class="form-control" [(ngModel)]="SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent" min="0" tabindex="81" />

            </div>

          </div>

        </div>
        <div class="col-6">
          <ng-container *ngIf="IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent) || IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent)">
            <ng-container>התראה תוצג כאשר</ng-container>
            <ng-container *ngIf="IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent)"> מחיר המוצר גדול ב {{SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent}}% </ng-container>
            <ng-container *ngIf="IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent) && IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent)"> או </ng-container>
            <ng-container *ngIf="IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent)"> מחיר המוצר קטן ב {{SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent}}% </ng-container>
            <ng-container>ממחיר המחירון</ng-container>
          </ng-container>
        </div>
      </div>


      <div class="row my-2" *ngIf="IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListCeilingWarningPercent) || IsNumber(SupplierStoreConfiguration.ImportConfiguration.PriceListFloorWarningPercent)">
        <h5 class="col-3">התעלם בתהליך השלמת פרטים</h5>
        <div class="col-3">
          <checkbox [(model)]="SupplierStoreConfiguration.ImportConfiguration.IgnorePriceListWarnings" tabindex="90"></checkbox>
        </div>
        <div class="col-6">בעת ייבוא הזמנות האם המערכת תעלם מחריגות מחיר</div>
      </div>


    </ng-container>

    <div class="row my-2 mt-2">
      <div class="col-12  border-top">
        <h4><u> פורמט ייצוא מוצרים</u></h4>
      </div>
    </div>


    <div class="row my-2">
      <h5 class="col-3">קובץ תבנית</h5>
      <div class="col-3">
        <p style="width: max-content;">
          <uploadfile [mode]="1" [model]="ProductExportTemplateFiles()" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xml" (modelChange)="OnProductExportTemplateFileUploaded($event)"> </uploadfile>
        </p>
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2" *ngIf="SupplierStoreConfiguration?.ExportConfiguration?.ProductTemplates && SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0]?.Template">
      <h5 class="col-3">שם קובץ</h5>
      <div class="col-3">
        <input type="text" class="form-control" [(ngModel)]="SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0].FileName" tabindex="101" />
      </div>
      <div class="col-6"></div>
    </div>

    <div class="row my-2"  *ngIf="SupplierStoreConfiguration?.ExportConfiguration?.ProductTemplates && SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0]?.Template">
      <h5 class="col-3">סוג קובץ</h5>
      <div class="col-3">
        <select class="form-control" [(ngModel)]="SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0].FileType" *ngIf="SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0].FileType != 64;else fileTypeDescription">
          <option *ngFor="let item of ExportFileTypes" [value]="item">{{item | enumDescription : "FileType" }}</option>
        </select>
        <ng-template #fileTypeDescription>
          <span class="d-block mt-2 ml-4 pl-2 font-weight-bold" style="text-align: right;">
            {{SupplierStoreConfiguration.ExportConfiguration.ProductTemplates[0].FileType | enumDescription : "FileType" }}
          </span>

        </ng-template>
      </div>
      <div class="col-6"></div>
    </div>




    <!-- end input-->


    <div class="row my-2 border-top">
      <div class="col-12 mt-1">
        <button type="button" tabindex="1000" class="btn btn-lg btn-outline-success" (click)="SaveAndReturn();">
          <h4 class="d-inline-block mr-2">שמור</h4>
          <i class="fa fa-save"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="this.SaveInprogress">


    <ng-container *ngIf="PriceListOperation">
      <ng-container *ngIf="!PriceListOperationCompleteModel">
        <h3>אנא המתן...</h3>
        <div>מעדכן רשימת מוצרים לחנות {{StoreDetails.Description}} </div>
        <div>
          <ngb-progressbar type="info" [striped]="true" [value]="PriceListOperation?.PresentComplete" height="50px" [striped]="true" [animated]="true">
            <h2 class="position-absolute px-2">{{PriceListOperation?.Message}}</h2>
          </ngb-progressbar>
        </div>

      </ng-container>
      <ng-container *ngIf="PriceListOperationCompleteModel">

        <div>
          <h2>טעינה קבצים הושלמה</h2>

          <div class="mt-3 alert alert-success" role="alert" *ngIf="PriceListOperationCompleteModel.SuccessFiles.length">
            <p>
              <i class="far fa-file mr-2"></i>{{PriceListOperationCompleteModel.SuccessFiles.length}} קבצים מתוך {{PriceListOperationCompleteModel.TotalFiles.length}} נטענו בהצלחה!
            </p>
          </div>


          <div class="mt-3 alert alert-danger mb-4" role="alert" *ngIf="PriceListOperationCompleteModel.FailedFiles.length">
            הקבצים הבאים לא נטענו בהצלחה :
            <ul class="list-group  ">
              <li class="list-group-item  list-group-item-danger" *ngFor="let file of PriceListOperationCompleteModel.FailedFiles; index as i"><i class="far fa-file mr-2"></i> {{file.Message}}</li>
            </ul>

          </div>
          <button class="float-left btn btn-danger" (click)="FailedFilesBack()"> חזור</button>
        </div>


      </ng-container>

    </ng-container>

  </ng-container>
</div>



<ng-template #editDefaultShipmentMethods let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">מפוי שיטות משלוח</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <th scope="col"><i class="fas fa-plus-circle text-success" (click)="AddShipmentTextsToMethods(-1);"></i></th>
          <th scope="col">ערך מיובא </th>
          <th scope="col">מחיר </th>
          <th scope="col">שיטה</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let map of ShipmentTextsToMethods ; index as i">
          <tr *ngIf="map.Text === DefaultConstTextToMethod ;else DefaultConstTextToMethodTmp" class="bg-light">
            <td colspan="2">
              <h5 class="text-center my-2">ברירת מחדל</h5>
            </td>
            <td></td>
            <td>
              <shipment-type-selector [(model)]="map.ShipMethod" [Mode]="2" [ExtraValues]="[{id : DefaultConstTextToMethod, name : 'ללא ברירת מחדל'}]" [ngClass]="'d-inline-block'" [ShowSelfDistributers]="true"></shipment-type-selector>
            </td>
          </tr>
          <ng-template #DefaultConstTextToMethodTmp>
            <tr>
              <td scope="row" class="h4">
                <i class="fas fa-plus-circle text-success ml-1" (click)="AddShipmentTextsToMethods(i);"></i>
                <i class="far fa-trash-alt text-danger ml-1" (click)="RemoveShipmentTextsToMethods(i);"></i>
              </td>
              <td>
                <!-- <input type="text" class="form-control" [(ngModel)]="map.Text" tabindex="85" />-->
                <labeled-autocomple-textbox [title]="title" class="compact autocomplate-dropdown-menu-h150-scrollable" [(model)]="map.Text" [CngbTypeahead]="MappingKeySearch" OpenOnFocus="true" tabindex="85">
                </labeled-autocomple-textbox>
              </td>
              <td>
                <input type="number" class="form-control" [(ngModel)]="map.Price" />
              </td>
              <td>
                <shipment-type-selector [(model)]="map.ShipMethod" [Mode]="2" [ngClass]="'d-inline-block'" [ShowSelfDistributers]="true"></shipment-type-selector>
              </td>

            </tr>

          </ng-template>
        </ng-container>


      </tbody>
    </table>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('save')">עדכן</button>
    <button type="button" class="btn btn-outline-dark" (click)="c('cancel')">בטל</button>
  </div>

</ng-template>

<ng-template #editOrderCustomField let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">מיפוי תוספת שדות ידניים להזמנה</h4>
    <button type="button" class="close" aria-label="Close" (click)="c('cross')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="container">
      <div class="row font-weight-bold border-bottom ">
        <div class="col-1 p-0 px-1 ">#</div>
        <div class="col-3 p-0 px-1 text-center">כינוי</div>
        <div class="col-4 p-0 px-1 text-center">שדה מקובץ מקור</div>
        <div class="col-4 p-0 px-1 text-center">ערך ברירת מחדל</div>
      </div>
     
      <div class="row py-1 border-top border-light" *ngFor="let OrderCustomFieldsMap of OrderCustomFieldsMappingContiner; ">
        <div class="col-12 font-weight-bold mb-1" *ngIf="OrderCustomFieldsMap.orderField == 'Custom1' ">שדות להזמנה</div>
        <div class="col-12 font-weight-bold mb-1 border-top border-light" *ngIf="OrderCustomFieldsMap.orderField == 'CustomItem1' ">שדות לפריט בזמנה</div>
        <div class="col-1 p-0 px-1"><span class="badge badge-pill badge-light">{{ OrderCustomFieldsMap.customFieldIndex}}</span></div>
        <div class="col-3 p-0 px-1"> <input type="text" class="form-control form-control-sm" [(ngModel)]="OrderCustomFieldsMap.fieldAlias" tabindex="185" /></div>
        <div class="col-4 p-0 px-1">
          <select *ngIf="ImportFileFields" class="form-control form-control-sm" [(ngModel)]="OrderCustomFieldsMap.importColumnsId" (ngModelChange)="OnOrderCustomFieldsMapSelected(OrderCustomFieldsMap);" tabindex="185">
            <option [value]="null">ללא שיוך שדה</option>
            <option *ngFor="let item of ImportFileFields" [value]="item.id">{{item?.names[0] }}</option>
          </select>
        </div>
        <div class="col-4 p-0 px-1"> <input type="text" class="form-control form-control-sm" [(ngModel)]="OrderCustomFieldsMap.defaultValue" tabindex="185" /></div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="c('save')">עדכן</button>
    <button type="button" class="btn btn-outline-dark" (click)="c('cancel')">בטל</button>
  </div>
</ng-template>