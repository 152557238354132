<div>
  <h3> <!---->
    חיפוש : <input type="text" style="width:300px" #ngbTypeahead=ngbTypeahead ngModel="text" [ngbTypeahead]="AutoCompleteSearchDropPoint" [inputFormatter]="AutoCompleteFormat" [resultTemplate]="rt" (selectItem)="AutoCompleteItemSelected($event)">
  </h3>
</div>

<ng-template #rt let-r="result" let-t="term">
  <div class="d-flex align-items-center" style="width:300px">
    <img [src]="GetMarkerIcon(r)" alt="" style="height: 3rem;">
    <div>
      <div class="h6 my-1">
        <ngb-highlight *ngIf="r.Name && r.Name.indexOf(t.toLowerCase()) >= 0 ;else elseNameBlock" [result]="r.Name" [term]="t"></ngb-highlight>
        <ng-template #elseNameBlock>{{r.Name}}</ng-template>
      </div>

      <ng-container *ngIf="r.Address ;else elseAddressBlock">
        <div>
          <ngb-highlight *ngIf="r.Address.City && r.Address.City.indexOf(t.toLowerCase()) >= 0 ;else elseCityBlock" [result]="r.Address.City" [term]="t"></ngb-highlight>
          <ng-template #elseCityBlock>{{r.Address.City}}</ng-template>
        </div>
        <div>
          <ngb-highlight *ngIf="r.Address.Street && r.Address.Street.indexOf(t.toLowerCase()) >= 0 ;else elseStreetBlock" [result]="r.Address.Street" [term]="t"></ngb-highlight>
          <ng-template #elseStreetBlock>{{r.Address.Street}}</ng-template>
        </div>
        <div>
          <ngb-highlight *ngIf="r.Address.Company && r.Address.Company.indexOf(t.toLowerCase()) >= 0 ;else elseCompanyBlock" [result]="r.Address.Company" [term]="t"></ngb-highlight>
          <ng-template #elseCompanyBlock>{{r.Address.Company}}</ng-template>
        </div>
      </ng-container>
      <ng-template #elseAddressBlock>
        <div>
          <ngb-highlight *ngIf="r.FullAddress && r.FullAddress.indexOf(t.toLowerCase()) >= 0 ;else elseFullAddressBlock" [result]="r.FullAddress" [term]="t"></ngb-highlight>
          <ng-template #elseFullAddressBlock>{{r.FullAddress}}</ng-template>
        </div>
      </ng-template>
    </div>
  </div>

</ng-template>

<google-map width="100%" [(zoom)]="MapZoom" [center]="MapCenter" [options]="MapOptions">
  <map-marker *ngFor="let droppoint of this.AvailableDropPoints" #somemarker="mapMarker" [position]="{lat: droppoint.GeoCoordinate.Latitude,lng: droppoint.GeoCoordinate.Longitude}" [clickable]="true" [icon]="GetMarkerIcon(droppoint)" [title]="droppoint.Name" (mapClick)="ShowInfoWindow(somemarker, droppoint)">
  </map-marker>

  <map-marker  *ngIf="AddressPoint" [position]="{lat: AddressPoint.GeoCoordinate.Latitude,lng: AddressPoint.GeoCoordinate.Longitude}" [icon]="GetMarkerIcon(AddressPoint)" [title]="AddressPoint.Name">
  </map-marker>

  <map-info-window>
    <ng-container *ngTemplateOutlet="droppointInfoDetailsWindow">
      This text is not displayed
    </ng-container>
  </map-info-window>
</google-map>


<ng-template #droppointInfoDetailsWindow>
  <div dir="rtl" class="ml-4" style="width:400px" *ngIf="SelectedDropPoint">
    <h2 class="border-bottom border-dark text-center">
      {{SelectedDropPoint.Name}}
    </h2>
    <ng-container *ngIf="SelectedDropPoint.ItemType == 'BoxitDropPoint'">

      <div>
        <ng-container *ngIf="SelectedDropPoint.Address">
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Street">{{SelectedDropPoint.Address.Street}}, {{SelectedDropPoint.Address.HouseNum}}</div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Entrance || SelectedDropPoint.Address.Floor || SelectedDropPoint.Address.Apartment">
            <ng-container *ngIf="SelectedDropPoint.Address.Entrance"> כניסה {{SelectedDropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Floor"> קומה {{SelectedDropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Apartment"> דירה {{SelectedDropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.POBox || SelectedDropPoint.Address.Zip || SelectedDropPoint.Address.Company">
            <ng-container *ngIf="SelectedDropPoint.Address.POBox">תא דואר {{SelectedDropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Company"> חברה {{SelectedDropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Zip"> מיקוד {{SelectedDropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.City">{{SelectedDropPoint.Address.City}}</div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Remarks">הערות: {{SelectedDropPoint.Address.Remarks}}</div>
        </ng-container>
      </div>
      <div class="m-0 my-3">
        <div class="h6 pl-1" *ngIf="$any(SelectedDropPoint).ShopPhone">טלפון: {{$any(SelectedDropPoint).ShopPhone}}</div>
        <div class="h6 pl-1" *ngIf="$any(SelectedDropPoint).OpeningHours">שעות פתיחה: {{$any(SelectedDropPoint).OpeningHours}}</div>
      </div>

    </ng-container>
    <ng-container *ngIf="SelectedDropPoint.ItemType == 'ChitaDropPoint' || SelectedDropPoint.ItemType == 'YDMDropPoint' || SelectedDropPoint.ItemType == 'SosnaDropPoint' ||
     SelectedDropPoint.ItemType == 'EPostDropPoint' || SelectedDropPoint.ItemType == 'OrionDropPoint'|| SelectedDropPoint.ItemType == 'CargoShopsDropPoint'">

      <div>
        <ng-container *ngIf="SelectedDropPoint.Address">
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Street">{{SelectedDropPoint.Address.Street}}, {{SelectedDropPoint.Address.HouseNum}}</div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Entrance || SelectedDropPoint.Address.Floor || SelectedDropPoint.Address.Apartment">
            <ng-container *ngIf="SelectedDropPoint.Address.Entrance"> כניסה {{SelectedDropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Floor"> קומה {{SelectedDropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Apartment"> דירה {{SelectedDropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.POBox || SelectedDropPoint.Address.Zip || SelectedDropPoint.Address.Company">
            <ng-container *ngIf="SelectedDropPoint.Address.POBox">תא דואר {{SelectedDropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Company"> חברה {{SelectedDropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Zip"> מיקוד {{SelectedDropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.City">{{SelectedDropPoint.Address.City}}</div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Remarks">הערות: {{SelectedDropPoint.Address.Remarks}}</div>
        </ng-container>
        <ng-container *ngIf="!SelectedDropPoint.Address && SelectedDropPoint.FullAddress ">
          <div class="h5 pl-1 m-0">{{SelectedDropPoint.FullAddress}}</div>
        </ng-container>
      </div>
      <div class="m-0 my-3">
        <div class="h6 pl-1" *ngIf="$any(SelectedDropPoint).Remarks">
          <ng-container *ngIf="$any(SelectedDropPoint).Remarks?.indexOf('שעות') == -1">
            שעות פתיחה:
          </ng-container>
          {{$any(SelectedDropPoint).Remarks}}
        </div>
      </div>

    </ng-container>
    <ng-container *ngIf="SelectedDropPoint.ItemType == 'UPSDropPoint'">

      <div>
        <ng-container *ngIf="SelectedDropPoint.Address">
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Street">{{SelectedDropPoint.Address.Street}}, {{SelectedDropPoint.Address.HouseNum}}</div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Entrance || SelectedDropPoint.Address.Floor || SelectedDropPoint.Address.Apartment">
            <ng-container *ngIf="SelectedDropPoint.Address.Entrance"> כניסה {{SelectedDropPoint.Address.Entrance}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Floor"> קומה {{SelectedDropPoint.Address.Floor}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Apartment"> דירה {{SelectedDropPoint.Address.Apartment}}</ng-container>
          </div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.POBox || SelectedDropPoint.Address.Zip || SelectedDropPoint.Address.Company">
            <ng-container *ngIf="SelectedDropPoint.Address.POBox">תא דואר {{SelectedDropPoint.Address.POBox}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Company"> חברה {{SelectedDropPoint.Address.Company}}</ng-container>
            <ng-container *ngIf="SelectedDropPoint.Address.Zip"> מיקוד {{SelectedDropPoint.Address.Zip}}</ng-container>
          </div>
          <div class="h5 pl-1 m-0" *ngIf="SelectedDropPoint.Address.City">{{SelectedDropPoint.Address.City}}</div>
          <div class="h6 pl-1 m-0" *ngIf="SelectedDropPoint.Address.Remarks">הערות: {{SelectedDropPoint.Address.Remarks}}</div>
        </ng-container>
      </div>
      <div class="m-0 my-3">
        <div class="h6 pl-1" *ngIf="$any(SelectedDropPoint).ShopPhone">טלפון: {{$any(SelectedDropPoint).ShopPhone}}</div>
        <div class="h6 pl-1" *ngIf="$any(SelectedDropPoint).Comments"> {{$any(SelectedDropPoint).Comments}}</div>
      </div>

    </ng-container>
    <button class="btn btn-outline-success btn-block" onclick="__SelectDropPoint();"> בחר נקודה<i class="fas fa-check mx-2"></i></button>
  </div>

</ng-template>