import { Injectable } from "@angular/core";
import { ISupplierConfiguration, ISupplierStoreConfiguration, ISupplierShipmentConfiguration, ISupplierBoxitShipmentConfiguration, ISupplierManualShipmentConfiguration, ISupplierPickupShipmentConfiguration, ISupplierShipmentExportConfiguration, ISupplierCustomExportFileTemplateConfiguration, ISupplierExportConfiguration, ISupplierShipmentImportConfiguration, ISupplierFedExShipmentConfiguration, ISupplierChitaShopsShipmentConfiguration, ISupplierChitaDeliveryShipmentConfiguration, ISupplierPaxxiShipmentConfiguration, ISupplierLocalERPConfiguration, ERPDBType, ShipmentMethodFindStrategyType, ISupplierUPSShipmentConfiguration, ISupplierUPSPickupShipmentConfiguration, ISupplierYDMDeliveryShipmentConfiguration, ISupplierYDMShopsShipmentConfiguration, ERPType, IRetentionPolicyConfiguration, ISupplierMainSetting, ISupplierUser, ISupplierSosnaDeliveryShipmentConfiguration, ISupplierSosnaShopsShipmentConfiguration, ISupplierCargoDeliveryShipmentConfiguration, ISupplierHFDDeliveryShipmentConfiguration, ISupplierEPostShipmentConfiguration, IStockConfiguration, ISupplierBaldariShipmentConfiguration, ISupplierOrionDeliveryShipmentConfiguration, ISupplierOrionShopsShipmentConfiguration, MultiplyShipmentItemsStrategyType, IProductConfiguration,
   ISupplierLionWheeliShipmentConfiguration,ISupplierFocusDeliveryShipmentConfiguration,ISupplierCargoShopsDeliveryShipmentConfiguration } from "../models/suppliers";
import { Subject, Observable, BehaviorSubject, ReplaySubject, forkJoin, of } from "rxjs";
import { IApiResult } from "../models/api.model";
import { HttpClient } from "@angular/common/http";
import { map, tap, switchMap, first, takeLast, flatMap, mergeMap, last } from "rxjs/operators";
import { StoreService } from "./store.service";
declare var $: any;
import { ShipmentService } from "./shipment.service";
import { FileType, IExternalFile } from "../models/files.models";
import { IShipmentTypes, UndefinedShipmentType } from "../models/shipments.models";
import { IBaseQuery, ILongRunningOperation, IPageResult } from "../models/common.models";
import { AuthorizationService } from "./authurization.service";
import { CommonService, ConvertObjectToQuery } from "./common.service";
import { IProductSearchRequest } from "./product.service";
import { Store } from "@ngrx/store";


@Injectable()
export class AdminService {
  private _apiUrl = "/api/Admin";

  constructor(private _http: HttpClient, private _commonService: CommonService, private _shipmentService: ShipmentService) {


  }

  public GetSupplierMainSetting(): Observable<IApiResult<ISupplierMainSetting>> {
    return this._http.get<IApiResult<ISupplierMainSetting>>(this._apiUrl + '/GetSupplierMainSetting');
  }

  public SetSupplierMainSetting(setting: ISupplierMainSetting): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/SetSupplierMainSetting', setting);


    /*.pipe(
      mergeMap(result => {
        this.store.dispatch(UserSessionRefreshStart());
        if (result.Result) return this.store.select(sessionStateFeature.selectSession).pipe(map(session => !!session));
        else return of(false);
      })
    );
*/
  }


  public GetCurrentConfiguration(): Observable<IApiResult<ISupplierConfiguration>> {
    return this._http.get<IApiResult<ISupplierConfiguration>>(this._apiUrl + '/GetCurrentConfiguration');
  }

  public SetCurrentConfiguration(configuration: ISupplierConfiguration): Observable<IApiResult<ISupplierConfiguration>> {
    return this._http.post<IApiResult<ISupplierConfiguration>>(this._apiUrl + '/SetCurrentConfiguration', configuration);
    /*.pipe(
      tap(result => {
        this._currentConfigurationBS.next(result.Result);
        this._storeService.ResetStoreCache();
        this._shipmentService.ResetShipmentTypes()
      }));*/
  }

  /*
    public RefreshCurrentConfiguration(): Observable<ISupplierConfiguration> {
      return this.GetCurrentConfiguration().pipe(
        map(result => {
  
          if (result.Code === 0) {
            this._currentConfigurationBS.next(result.Result);
          }
          return result.Result;
        })
      );
    }
  */

  /*
  public LoadStorePriceList(store: string, uploadFileIds: string[]): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/LoadStorePriceList', { Store: store, FileIds: uploadFileIds });
  }

    public LoadProductCatalog(store: string, uploadFileIds: string[]): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/LoadProductCatalog', { Store: store, FileIds: uploadFileIds });
  }
*/
  public PrintStorePriceList(store: string, query: IProductSearchRequest): Observable<IApiResult<ILongRunningOperation>> {
    return this._http.post<any>(this._apiUrl + '/PrintStorePriceList/' + store, query);
  }


  /*
  public SetSupplierStoreConfiguration(idName: string, configuration: ISupplierStoreConfiguration): Observable<ISupplierStoreConfiguration> {
    this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
    this._currentConfiguration.Stores[idName] = configuration;
    return this.SetCurrentConfiguration().pipe(
      map(result => result.Code === 0 ? result.Result.Stores[idName] : this.CreateNewSupplierStoreConfiguration())
    );
  }

*/
  /*
    public SetSupplierShipmentConfiguration(idName: string, configuration: ISupplierShipmentConfiguration): Observable<ISupplierShipmentConfiguration> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.Shipments[idName] = configuration;
      return this.SetCurrentConfiguration().pipe(
        map(result => result.Code === 0 ? result.Result.Shipments[idName] : this.CreateNewSupplierShipmentConfiguration(idName))
      );
    }
  
  */

  /*
    public SetSupplierExports(exportsConfiguration: ISupplierExportConfiguration[]): Observable<ISupplierExportConfiguration[]> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.Exports = exportsConfiguration;
      return this.SetCurrentConfiguration().pipe(
        map(result => (result.Code === 0 && result.Result.Exports) ? result.Result.Exports : [])
      );
    }
  *//*
    public SetSupplierLocalErpConfiguration(erpConfiguration: ISupplierLocalERPConfiguration): Observable<ISupplierLocalERPConfiguration> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.LocalERPConfiguration = erpConfiguration;
      return this.SetCurrentConfiguration().pipe(
        map(result => (result.Code === 0 && result.Result.Exports) ? result.Result.LocalERPConfiguration : this.CreateNewSupplierLocalERPConfiguration())
      );
    }
    public SetSupplierRetentionPolicyConfiguration(retentionPolicy: IRetentionPolicyConfiguration): Observable<IRetentionPolicyConfiguration> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.RetentionPolicy = retentionPolicy;
      return this.SetCurrentConfiguration().pipe(
        map(result => (result.Code === 0 && result.Result.Exports) ? result.Result.RetentionPolicy : this.CreateNewSupplierRetentionPolicyConfiguration())
      );
    }
  
    public SetSupplierStockConfiguration(stockSetting: IStockConfiguration): Observable<IStockConfiguration> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.StockSetting = stockSetting;
      return this.SetCurrentConfiguration().pipe(
        map(result => (result.Code === 0 && result.Result.Exports) ? result.Result.StockSetting : this.CreateNewSupplierStockConfiguration())
      );
    }
  
    public SetSupplierProductConfiguration(productSetting: IProductConfiguration): Observable<IProductConfiguration> {
      this._currentConfiguration = $.extend(true, { Stores: {}, Shipments: {} }, this._currentConfiguration);
      this._currentConfiguration.ProductSetting = productSetting;
      return this.SetCurrentConfiguration().pipe(
        map(result => (result.Code === 0 && result.Result.Exports) ? result.Result.ProductSetting : this.CreateNewSupplierProductConfiguration())
      );
    }
  */

  public GetSupplierUsers(query: ISupplierUserQuery): Observable<IApiResult<IPageResult<ISupplierUser>>> {
    return this._http.get<IApiResult<IPageResult<ISupplierUser>>>(this._apiUrl + '/GetSupplierUsers?' + ConvertObjectToQuery(query));
  }

  public SaveSupplierUser(user: ISupplierUser, firstTimePassword?: string): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/SaveSupplierUser',
      {
        "SupplierUser": user,
        "FirstTimePassword": firstTimePassword
      });
  }

  public DeleteSupplierUser(userId: string, reason?: string): Observable<IApiResult<boolean>> {
    return this._http.post<IApiResult<boolean>>(this._apiUrl + '/DeleteSupplierUser',
      {
        "UserId": userId,
        "Reason": reason
      });
  }

  public CreateNewSupplierUser(): ISupplierUser {
    return {
      UserName: Math.random().toString(36).substring(7),
      UserIdentity: {
        FullName: ""
      },
      Roles: ["Admin"],
      Disable: false,
      // Audit: this._commonService.CreateNewItemAuditTrace(),
      SupplierId: null,
      Id: null
    }
  }
  public GetERPServiceUsername(): Observable<IApiResult<string>> {
    return this._http.get<IApiResult<string>>(this._apiUrl + '/GetERPServiceUsername');
  }


  public CreateNewSupplierStoreConfiguration(): ISupplierStoreConfiguration {

    return {
      Enabled: true,
      ItemType: "Basic",
      ERPNumber: "",
      RenameSalfDistributerDeliveryMethod: "",
      OrderCustomFieldAliases: {},
      ImportConfiguration: {
        IsSalfDistributer: false,
        ItemType: "Basic",
        OverrideDefaultFields: {},
        ShippingMethodDefaults: {},
        IgnoreOrderRemarks: false,
        IgnoreShipmentRemarks: false,
        MultiplyShipmentItemsStrategy: MultiplyShipmentItemsStrategyType.Keep,
        WarnOnZeroPriceOrderItem: false
      }
    };
  }
  public CreateNewSupplierShipmentConfiguration(shipmentTypeId: string): ISupplierShipmentConfiguration {

    var exportConf: ISupplierShipmentExportConfiguration = {
      EnableAddionLineInERPExport: false,
      IgnoreOrderRemarks: false,
      IgnoreShipmentRemarks: false
    }

    var importConf: ISupplierShipmentImportConfiguration = {
      DefaultPrice: 0,
      Description: "",
      SKU: ""
    }

    var manualMatch = /^Manual(\d\d?)DeliveryShipment$/.exec(shipmentTypeId);

    if (shipmentTypeId == "BoxItShipment") {
      let conf: ISupplierBoxitShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "Boxit",
        LocationSearchDistance: 50,
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "ChitaDeliveryShipment") {
      let conf: ISupplierChitaDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "ChitaDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "ChitaShopsShipment") {
      let conf: ISupplierChitaShopsShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "ChitaShops",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "YDMDeliveryShipment") {
      let conf: ISupplierYDMDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "YDMDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "FocusDeliveryShipment") {
      let conf: ISupplierFocusDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "FocusDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "YDMShopsShipment") {
      let conf: ISupplierYDMShopsShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "YDMShops",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false
      }
      return conf;
    }
    else if (shipmentTypeId == "OrionDeliveryShipment") {
      let conf: ISupplierOrionDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "OrionDelivery",
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false,
        PickupContact: this._commonService.CreateNewIIdentity(),
        PickupAddress: this._commonService.CreateNewIAddress()
      }
      return conf;
    }
    else if (shipmentTypeId == "OrionShopsShipment") {
      let conf: ISupplierOrionShopsShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "OrionShops",
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false,
        PickupContact: this._commonService.CreateNewIIdentity(),
        PickupAddress: this._commonService.CreateNewIAddress()
      }
      return conf;
    }
    else if (shipmentTypeId == "PaxxiDeliveryShipment") {
      let conf: ISupplierPaxxiShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "Paxxi",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "UPSDeliveryShipment") {
      let conf: ISupplierUPSShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "UPSDelivery",
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "UPSPickUpShipment") {
      let conf: ISupplierUPSPickupShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "UPSPickUp",
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "SosnaDeliveryShipment") {
      let conf: ISupplierSosnaDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "SosnaDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false,


      }
      return conf;
    }
    else if (shipmentTypeId == "SosnaShopsShipment") {
      let conf: ISupplierSosnaShopsShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "SosnaShops",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "HFDDeliveryShipment") {
      let conf: ISupplierHFDDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "HFDDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "EPostShipment") {
      let conf: ISupplierEPostShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "EPost",
        CustomerID: "",
        AuthenticationToken: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "CargoDeliveryShipment") {
      let conf: ISupplierCargoDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "CargoDelivery",
        CustomerID: "",
        CustomerName: "",
        /*  Password: "",
          UserName: "",*/
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (shipmentTypeId == "CargoShopsDeliveryShipment") {
      let conf: ISupplierCargoShopsDeliveryShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "CargoShopsDelivery",
        CustomerID: "",
        AuthenticationToken: "",
        PickupAddress:this._commonService.CreateNewIAddress(),
        Phone : "",
        Email : "",
        CustomerName: "",
        /*  Password: "",
          UserName: "",*/
        AutoIgnoreErrors: false,
      }
      return conf;
    }
    else if (shipmentTypeId == "FedExShipment") {
      let conf: ISupplierFedExShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "FedEx",
        CustomerID: "",
        Password: "",
        UserName: "",
        AutoIgnoreErrors: false,

      }
      return conf;
    }
    else if (manualMatch) {
      let conf: ISupplierManualShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: `Manual${manualMatch[1]}`,
        AliasName: `שליח עצמי - ${manualMatch[1]}`,
        AutoIgnoreErrors: false,
        AutoTrackingNumber: false
      }
      return conf;
    }
    else if (shipmentTypeId == "PickupShipment") {
      let conf: ISupplierPickupShipmentConfiguration = {
        PickupAddress: null,
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "Pickup",
        AliasName: "איסוף עצמי",
        AutoIgnoreErrors: false,
        AutoTrackingNumber: false
      }
      return conf;
    }
    else if (shipmentTypeId == "BaldarDeliveryShipment") {
      let conf: ISupplierBaldariShipmentConfiguration = {
        ItemType: "Baldar",
        AutoIgnoreErrors: false,
        Enabled: false,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,

      }
      return conf;
    }
    else if (shipmentTypeId == "LionWheelDeliveryShipment") {
      let conf: ISupplierLionWheeliShipmentConfiguration = {
        ItemType: "LionWheel",
        AutoIgnoreErrors: false,
        Enabled: false,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        AuthenticationToken: "",
      }
      return conf;
    }
    else {
      let conf: ISupplierShipmentConfiguration = {
        Enabled: true,
        ExportConfiguration: exportConf,
        ImportConfiguration: importConf,
        ItemType: "Basic",
        AutoIgnoreErrors: false,
        AutoTrackingNumber: false
      }
      return conf;
    }

  }
  public CreateNewSupplierRetentionPolicyConfiguration(): IRetentionPolicyConfiguration {
    return {
      ShoppersPIIDataExpirationDays: 21,
      ShoppersPIIDataExpirationEnabled: false,
      OrderAuditEnabled: false
    }
  }

  public CreateNewSupplierStockConfiguration(): IStockConfiguration {
    return {
      Enable: false,
      MinimumStockLevel: 0,
    }
  }
  public CreateNewSupplierProductConfiguration(): IProductConfiguration {
    return {
      PullSource: {
        Password: null,
        Url: null,
        User: null
      },
      PullPostProcessCopy: {
        Password: null,
        Url: null,
        User: null
      }
    }
  }
  public CreateNewSupplierLocalERPConfiguration(): ISupplierLocalERPConfiguration {
    return {
      Type: ERPType.SBOOnPrem,
      DatabaseType: ERPDBType.NotDefined,
      Database: "",
      Token: "",
      Password: "",
      Server: "",
      Username: "",
      Active: true,
      IncludeShipmentItems: true,
      FieldMapping: {
        "NumAtCard": "SupplierNumber",
        "important": "SupplierNumber",
        "CardCode": "Source.OriginStoreERPNumber",
        "DocDate": "Audit.CreatedAt",
        "docduedate": "Audit.CreatedAt",
        "ItemCode": "Items.Product.SKU",
        "Quantity": "Items.Quantity",
        "Address2": "Shipments.Address.FullAddress",
        "AddressCity": "Shipments.Address.City",
        "AddressZip": "Shipments.Address.Zip",
        "AddressEmail": "Shipments.Recipient.Email",
        "AddressPhone": "Shipments.Recipient.Phone",
        "AddressPhone2": "Shipments.Recipient.Phone2",
        "Comments": "AllRemarks",
        "BpChCode": "Club",
      },
      CustomerId: ""
    }
  }

  public CreateNewSupplierExportTemplateConfiguration(name: string, fileType: FileType, fileName: string, template: IExternalFile): ISupplierCustomExportFileTemplateConfiguration {
    return {
      Name: name,
      ItemType: "ExportFileTemplate",
      Template: template,
      FileType: fileType,
      FileName: fileName
    }

  }
  public GetShipmentStrategiesDefault(): ShipmentMethodFindStrategyType[] {

    return [
      ShipmentMethodFindStrategyType.ShopDefault,
      ShipmentMethodFindStrategyType.MasterProduct,
      ShipmentMethodFindStrategyType.ShipmentOrderItemSKU];
  }

  public GetSuppliersList(): Observable<{ Name: string, Id: string }[]> {
    return this._http.get<IApiResult<{ Name: string, Id: string }[]>>(this._apiUrl + "/GetSuppliersList").pipe(
      map(r => {
        if (r.Result) return r.Result;
        else throw r.Message
      })
    );
  }

}
export interface ISupplierUserQuery extends IBaseQuery {
  UserName?: string,
  Role?: string,
  ExcludeRoles?: string[]
}

